import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
	useFlags,
	usePrefs,
	useSitemap,
	useRequestData
} from 'wsm-common-data';
import { useSelector } from 'react-redux';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import throttle from '../util/modern/throttle';
import generateUID from '../util/generateUID';
import { g } from '../global-constants';
import DropDown from '../components/modern/DropDown';
import InputDisplay from '../components/modern/InputDisplay';
import fetchInventory from '../util/modern/fetchInventory';
import fetchPages from '../util/modern/fetchPages';
import TrackingEvent from '../util/modern/trackingEvent';
import transformInventoryResults from '../util/modern/transformInventoryResults';
import getRichContentResults from '../util/modern/getRichContentResults';
import ButtonDisplay from '../components/modern/ButtonDisplay';
import FlyOut from '../components/modern/FlyOut';

const ModernContainer = ({ type, isMobile }) => {
	if (typeof window !== 'undefined') {
		window.DDC = window.DDC || {};
		window.DDC.WidgetData = window.DDC.WidgetData || {};
	}

	const flags = useFlags();
	const sitemap = useSitemap();
	const {
		numberOfCharactersForFirstSearch,
		overrideAllInventoryAlias,
		showInventoryResults,
		showHoursResults,
		showAddressResult,
		showEmailResult,
		showPhoneNumberResults
	} = usePrefs();

	const numberOfCharactersForFirstSearchNumber = parseInt(
		numberOfCharactersForFirstSearch,
		10
	);

	const { windowId, locale, widgetName } = useRequestData();
	const inputRef = useRef(null);
	const dropDownRef = useRef(null);
	const openButtonRef = useRef(null);
	const inputElementRef = useRef(null);

	const {
		accountId,
		siteId,
		widgetSearchServiceUri,
		configTypes,
		pageAlias
	} = useSelector((state) => state.widgetData);

	const [dropDownPosition, setDropDownPosition] = useState({
		top: 0,
		left: 0
	});
	const [inputWidth, setInputWidth] = useState(0);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [inventorySearchResults, setInventorySearchResults] = useState();
	const [pageSearchResults, setPageSearchResults] = useState();
	const [addInfo, setAddInfo] = useState('');
	const [richContentToDisplay, setRichContentToDisplay] = useState([]);
	const [fetchingResults, setFetchingResults] = useState(false);

	const hasNoResults = !!(
		searchTerm &&
		!pageSearchResults &&
		!inventorySearchResults
	);

	const hasSearchResults =
		pageSearchResults?.length > 0 ||
		inventorySearchResults?.suggestions?.length > 0 ||
		inventorySearchResults?.vehicles?.length > 0;

	const fetchAddInfo = useCallback(async () => {
		if (typeof window !== 'undefined' && addInfo === '') {
			// this code eventually used for wsm-contact-transformer which looks for 'iPhone'
			// in userAgent so doing a tricky thing so we do not have to pass whole userAgent

			const userAgent = window.navigator.userAgent.includes('iPhone')
				? 'iPhone'
				: 'not-apple-mobile-device';

			const fetchedAddInfo = await fetch(
				`${widgetSearchServiceUri}/addData/${accountId}/${userAgent}/${siteId}/${locale}/${widgetName}`
			);
			const info = await fetchedAddInfo.json();

			setAddInfo(info);
		}
	}, [
		accountId,
		addInfo,
		locale,
		siteId,
		widgetName,
		widgetSearchServiceUri
	]);

	const updateDropDownPosition = () => {
		if (inputRef.current) {
			const inputRect = inputRef.current.getBoundingClientRect();
			setDropDownPosition({
				top: inputRect.bottom + window.scrollY,
				left: inputRect.left + window.scrollX
			});
			setInputWidth(inputRect.width);
		}
	};

	const getInitialRecentSearches = () => {
		if (typeof localStorage === 'undefined') {
			return [];
		}
		try {
			const storedSearches = localStorage.getItem(g.RECENT_SEARCHES_KEY);
			return storedSearches ? JSON.parse(storedSearches) : [];
		} catch (error) {
			return [];
		}
	};

	const [recentSearches, setRecentSearches] = useState(
		getInitialRecentSearches
	);

	const RESULTS_PAGE_ALIAS = 'SITE_TEXT_SEARCH_RESULTS';
	const FOUR_ZERO_FOUR_PAGE_ALIAS = '404';
	const pageNotFoundPath = sitemap.get(FOUR_ZERO_FOUR_PAGE_ALIAS);
	const resultsPageAlias =
		sitemap.get(RESULTS_PAGE_ALIAS) || pageNotFoundPath;

	const allInventoryPath =
		(overrideAllInventoryAlias
			? sitemap.get(overrideAllInventoryAlias)
			: sitemap.get('INVENTORY_LISTING_DEFAULT_AUTO_ALL')) ||
		pageNotFoundPath;
	const { triggerTrackingEvent } = new TrackingEvent(windowId, widgetName);

	const openModal = useCallback(() => {
		if (
			(!modalIsOpen &&
				!window?.DDC?.WidgetData?.[widgetName]?.modalIsOpen &&
				(recentSearches?.length > 0 ||
					hasSearchResults ||
					searchTerm?.length >
						numberOfCharactersForFirstSearchNumber)) ||
			type !== 'input' ||
			richContentToDisplay.length > 0
		) {
			window.DDC.WidgetData[widgetName] = {
				modalIsOpen: true
			};
			triggerTrackingEvent({
				element: 'search box',
				result: 'search modal initiated',
				action: 'clicked'
			});
			setRecentSearches(getInitialRecentSearches);
			setNewRelicCustomAttribute('wsSiteTextSearch-modalOpened', 'true');
			setModalIsOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalIsOpen, widgetName, searchTerm]);

	const closeModal = (closeMethod = 'background overlay') => {
		window.DDC.WidgetData[widgetName] = {
			modalIsOpen: false
		};
		setModalIsOpen(false);
		setSearchTerm('');
		document.body.style.overflow = 'auto';
		triggerTrackingEvent({
			element: closeMethod,
			result: 'search modal closed'
		});
	};

	const updateRecentSearches = () => {
		const sanitizedTerm = searchTerm?.trim()?.substring(0, 100);
		if (!sanitizedTerm) {
			return;
		}
		try {
			const searches =
				JSON.parse(localStorage.getItem(g.RECENT_SEARCHES_KEY)) || [];
			const filteredSearch = searches.filter(
				(search) => search.value !== sanitizedTerm
			);

			if (filteredSearch.length >= 10) {
				filteredSearch.pop();
			}

			const newSearch = {
				id: generateUID('recent_search'),
				value: sanitizedTerm
			};

			const newRecentSearches = [newSearch, ...filteredSearch];
			localStorage.setItem(
				g.RECENT_SEARCHES_KEY,
				JSON.stringify(newRecentSearches)
			);
			setRecentSearches(newRecentSearches);
		} catch (error) {
			// add New Relic error tracking
		}
	};

	const removeRecentSearch = (id) => {
		setRecentSearches((prevState) => {
			const updatedSearches = prevState.filter(
				(search) => search.id !== id
			);
			try {
				localStorage.setItem(
					g.RECENT_SEARCHES_KEY,
					JSON.stringify(updatedSearches)
				);
			} catch (error) {
				// add New Relic error tracking
			}

			if (updatedSearches.length === 0) {
				setModalIsOpen(false);
			}

			return updatedSearches;
		});
	};

	const reorderRecentSearches = (clickedSearch) => {
		const updatedSearches = recentSearches.filter(
			(search) => search.id !== clickedSearch.id
		);
		const newRecentSearches = [clickedSearch, ...updatedSearches];

		setRecentSearches(newRecentSearches);

		try {
			localStorage.setItem(
				g.RECENT_SEARCHES_KEY,
				JSON.stringify(newRecentSearches)
			);
		} catch (error) {
			// Add New Relic error tracking
		}
	};
	const getPageResults = async (query) => {
		if (showInventoryResults !== 'true') return undefined;

		const pageSearchResponse = await fetchPages({
			searchTerm: query,
			siteId,
			locale,
			widgetSearchServiceUri
		});

		return pageSearchResponse?.sort((a, b) => b.score - a.score);
	};

	const getInventoryResults = async (query) => {
		const inventoryFetchTimeout =
			flags['ws-site-text-search-inventory-client-timeout'];

		if (showInventoryResults !== 'true') return undefined;

		const inventorySearchResponse = await fetchInventory({
			searchTerm: query,
			siteId,
			locale,
			widgetSearchServiceUri,
			configTypes,
			resultsPageAlias,
			inventoryFetchTimeout
		});
		if (inventorySearchResponse) {
			const {
				suggestions,
				vehicles,
				searchTerm: responseSearchTerm
			} = inventorySearchResponse;

			if (responseSearchTerm === query.replace('+', ' ')) {
				let transformedSuggestions = [];
				if (suggestions && suggestions.length) {
					transformedSuggestions = transformInventoryResults(
						suggestions,
						query,
						{
							allInventoryPath,
							newInventoryPath: sitemap.get(
								'INVENTORY_LISTING_DEFAULT_AUTO_NEW'
							),
							usedInventoryPath: sitemap.get(
								'INVENTORY_LISTING_DEFAULT_AUTO_USED'
							)
						}
					);
				}
				return {
					suggestions: transformedSuggestions?.sort(
						(a, b) => b.count - a.count
					),
					vehicles
				};
			}
		}
		return [];
	};

	const setRichContentResults = (query) => {
		const richContentToHide = [];
		if (showPhoneNumberResults === 'false') {
			richContentToHide.push(
				'phone-sales',
				'phone-service',
				'phone-parts',
				'phone-commercial'
			);
		}
		if (showEmailResult === 'false') {
			richContentToHide.push('email');
		}
		if (showAddressResult === 'false') {
			richContentToHide.push('address');
		}
		if (showHoursResults === 'false') {
			richContentToHide.push(
				'hours-sales',
				'hours-service',
				'hours-parts',
				'hours-commercial'
			);
		}
		const richContentResults = getRichContentResults(
			query,
			numberOfCharactersForFirstSearchNumber,
			richContentToHide,
			locale
		);
		setRichContentToDisplay(richContentResults);
	};

	const debounce =
		typeof window !== 'undefined' && window._ && window._.debounce
			? window._.debounce
			: function debounce(action, wait) {
					let timeoutId;
					return function debouncer() {
						clearTimeout(timeoutId);
						timeoutId = setTimeout(action, wait);
					};
			  };
	const debouncedFetch = useCallback(
		debounce(async (query) => {
			setFetchingResults(true);
			const [pageSearchResponse, inventorySearchResponse] =
				await Promise.all([
					getPageResults(query),
					getInventoryResults(query)
				]);

			setPageSearchResults(pageSearchResponse);
			setInventorySearchResults(inventorySearchResponse);
			setRichContentResults(query);

			if (query.length >= numberOfCharactersForFirstSearchNumber) {
				setModalIsOpen(true);
			}
			setFetchingResults(false);
		}, 250),
		[]
	);

	const handleQuerySubmission = (event) => {
		event?.preventDefault();
		if (fetchingResults) return;
		setNewRelicCustomAttribute('wsSiteTextSearch-onSubmit', 'true');
		// If a single vehicle is returned (as a VehicleCard),
		// go straight to the VDP without stopping at the search results page.
		if (
			inventorySearchResults?.vehicles?.length === 1 &&
			inventorySearchResults?.vehicles[0]?.path
		) {
			triggerTrackingEvent({
				action: 'clicked',
				element: 'vin stock',
				result: 'navigated to VDP'
			});
			const { path: singleVehiclePath } =
				inventorySearchResults.vehicles[0];
			setNewRelicCustomAttribute(
				'wsSiteTextSearch-onSubmitSingleVehicleCard',
				'true'
			);
			window.location.href = singleVehiclePath || null;
			// If an Inventory or Quick Links result is selected (with the arrow keys or on hover),
			// go straight to the link without stopping at the search results page.
		} else if (hasNoResults) {
			// No suggestions are returned, prevent navigating to the search results page
			// except for non inventory search terms like 'parts', 'service' etc
			setNewRelicCustomAttribute(
				'wsSiteTextSearch-onSubmitNoResultsNotRedirected',
				'true'
			);
			event.preventDefault();
		} else if (
			inventorySearchResults.suggestions &&
			inventorySearchResults.suggestions.length
		) {
			// Navigate to the 'all-inventory' page when suggestions are returned
			setNewRelicCustomAttribute(
				'wsSiteTextSearch-onSubmitRedirectedToAllInventory',
				'true'
			);
			window.location.href = `${allInventoryPath}?search=${encodeURIComponent(
				searchTerm
			)}`;
		} else {
			// For all other results, navigate to the search results page
			triggerTrackingEvent({
				element: 'submit button',
				result: 'text search form submitted',
				fieldValue: searchTerm
			});
			setNewRelicCustomAttribute(
				'wsSiteTextSearch-onSubmitRedirectedToSearchResults',
				'true'
			);
			window.location.href = `${resultsPageAlias}?search=${encodeURIComponent(
				searchTerm
			)}`;
		}
	};

	const handleSubmission = (event) => {
		if (!searchTerm?.trim()) return;
		event?.preventDefault();
		updateRecentSearches(searchTerm);
		handleQuerySubmission(event);
		if (!modalIsOpen) {
			setModalIsOpen(true);
		}
	};

	const handleClearHistory = () => {
		try {
			localStorage.removeItem(g.RECENT_SEARCHES_KEY);
		} catch (error) {
			// add New Relic error tracking
		}
		inputElementRef.current?.focus();
		setRecentSearches([]);
		setModalIsOpen(false);
	};

	const handleClearHistoryNoClose = () => {
		try {
			localStorage.removeItem(g.RECENT_SEARCHES_KEY);
		} catch (error) {
			// add New Relic error tracking
		}
		inputElementRef.current?.focus();
		setRecentSearches([]);
	};

	const handleClickOutside = (event) => {
		if (
			inputRef.current &&
			!inputRef.current.contains(event.target) &&
			dropDownRef.current &&
			!dropDownRef.current.contains(event.target)
		) {
			closeModal();
		}
	};

	useEffect(() => {
		const throttledUpdateDropDownPosition = throttle(
			updateDropDownPosition,
			150
		);

		if (modalIsOpen) {
			updateDropDownPosition();
			window.addEventListener('resize', throttledUpdateDropDownPosition);
			document.addEventListener('mousedown', handleClickOutside);
		}
		return () => {
			window.removeEventListener(
				'resize',
				throttledUpdateDropDownPosition
			);
			throttledUpdateDropDownPosition.cancel();
			document.removeEventListener('mousedown', handleClickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalIsOpen]);

	const handleOnChangeInput = (searchTermValue) => {
		setSearchTerm(searchTermValue);
		if (searchTermValue >= numberOfCharactersForFirstSearchNumber) {
			setModalIsOpen(true);
		} else if (searchTermValue === 0 && recentSearches.length === 0) {
			setModalIsOpen(false);
		}
	};

	const handleChipClick = (value) => {
		setSearchTerm(value);
	};

	const handleLinkClick = () => {
		updateRecentSearches();
	};

	useEffect(() => {
		const trimmedSearchTerm = searchTerm.trim();

		if (
			trimmedSearchTerm?.length >= numberOfCharactersForFirstSearchNumber
		) {
			debouncedFetch(trimmedSearchTerm);
		} else if (
			trimmedSearchTerm?.length < numberOfCharactersForFirstSearchNumber
		) {
			setRichContentToDisplay([]);
			setInventorySearchResults(undefined);
			setPageSearchResults(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm]);

	useEffect(() => {
		// This effect only runs once to setup specific data for this component
		fetchAddInfo();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	let ModernContainerContent = null;

	switch (type) {
		case 'input':
			ModernContainerContent = (
				<>
					<InputDisplay
						inputRef={inputRef}
						inputElementRef={inputElementRef}
						openModal={openModal}
						isOpen={modalIsOpen}
						onClear={() => {
							setSearchTerm('');
						}}
						triggerTrackingEvent={triggerTrackingEvent}
						type={g.INPUT}
						data-testid="modern-input"
						handleSubmission={handleSubmission}
						searchTerm={searchTerm}
						handleOnChangeInput={handleOnChangeInput}
					/>
					{modalIsOpen && !isMobile && (
						<DropDown
							dropDownRef={dropDownRef}
							inputRef={inputRef}
							inputElementRef={inputElementRef}
							inputWidth={inputWidth}
							isOpen={modalIsOpen}
							recentSearches={recentSearches}
							setModalIsOpen={setModalIsOpen}
							dropDownPosition={dropDownPosition}
							onRemove={removeRecentSearch}
							handleClearHistory={handleClearHistory}
							onReorder={reorderRecentSearches}
							pageResults={pageSearchResults}
							inventoryResults={inventorySearchResults}
							searchTerm={searchTerm}
							numberOfCharactersForFirstSearchNumber={
								numberOfCharactersForFirstSearchNumber
							}
							handleChipClick={handleChipClick}
							handleLinkClick={handleLinkClick}
							addInfo={addInfo}
							richContentToDisplay={richContentToDisplay}
							triggerTrackingEvent={triggerTrackingEvent}
							pageAlias={pageAlias}
							windowId={windowId}
							closeModal={closeModal}
						/>
					)}
					{modalIsOpen && isMobile && (
						<FlyOut
							isOpen={modalIsOpen}
							onClose={() => {
								closeModal();
							}}
							onClear={() => {
								setSearchTerm('');
							}}
							openButtonRef={openButtonRef}
							closeModal={closeModal}
							inputElementRef={inputElementRef}
							handleOnChangeInput={handleOnChangeInput}
							searchTerm={searchTerm}
							inventoryResults={inventorySearchResults}
							handleLinkClick={handleLinkClick}
							addInfo={addInfo}
							richContentToDisplay={richContentToDisplay}
							triggerTrackingEvent={triggerTrackingEvent}
							numberOfCharactersForFirstSearchNumber={
								numberOfCharactersForFirstSearchNumber
							}
							pageResults={pageSearchResults}
							recentSearches={recentSearches}
							handleClearHistory={handleClearHistoryNoClose}
							onRemove={removeRecentSearch}
							onReorder={reorderRecentSearches}
							handleChipClick={handleChipClick}
							handleSubmission={handleSubmission}
						/>
					)}
				</>
			);
			break;
		case 'button':
		default:
			ModernContainerContent = (
				<>
					<ButtonDisplay
						openButtonRef={openButtonRef}
						openModal={openModal}
						type={type}
						triggerTrackingEvent={triggerTrackingEvent}
						data-testid="modern-input"
					/>
					{modalIsOpen && (
						<FlyOut
							isOpen={modalIsOpen}
							onClose={() => {
								closeModal();
							}}
							onClear={() => {
								setSearchTerm('');
							}}
							openButtonRef={openButtonRef}
							closeModal={closeModal}
							inputElementRef={inputElementRef}
							handleOnChangeInput={handleOnChangeInput}
							searchTerm={searchTerm}
							inventoryResults={inventorySearchResults}
							handleLinkClick={handleLinkClick}
							addInfo={addInfo}
							richContentToDisplay={richContentToDisplay}
							triggerTrackingEvent={triggerTrackingEvent}
							inputRef={inputRef}
							numberOfCharactersForFirstSearchNumber={
								numberOfCharactersForFirstSearchNumber
							}
							pageResults={pageSearchResults}
							recentSearches={recentSearches}
							handleClearHistory={handleClearHistoryNoClose}
							onRemove={removeRecentSearch}
							onReorder={reorderRecentSearches}
							handleChipClick={handleChipClick}
							handleSubmission={handleSubmission}
						/>
					)}
				</>
			);
	}

	return ModernContainerContent;
};

export default ModernContainer;
